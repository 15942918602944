import { graphql, Link, PageProps, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Sticky, StickyContainer } from 'react-sticky'
import styled from 'styled-components'
import Container from '../../../../components/Container'
import Footer from '../../../../components/Footer/Footer'
import Intro from '../../../../components/Intro'
import { Site } from '../../../../components/Site'
import { Main } from '../../../../components/Styled'

const menu = [
  { title: 'The daisee Process', id: 'process' },
  { title: 'What you need to prepare', id: 'prepare' },
  { title: 'How we will work together', id: 'work' },
  { title: ' Onboard & Training', id: 'onboarding' },
  { title: 'Check-ins', id: 'checkin' },
  { title: 'Further Support', id: 'support' },
  { title: 'FAQ', id: 'faq' },
  { title: 'Terms & Conditions', id: 'terms' }
]

const GenesysLanding: React.FC<PageProps> = props => {
  const refs = menu.reduce((acc, item) => ({ ...acc, [item.id]: React.createRef<HTMLDivElement>() }), {} as any)
  const [active, setActive] = React.useState('')

  function handleClick(id: string) {
    setActive(id)
    const ref = refs[id].current
    if (ref) {
      const yOffset = -96
      const y = ref.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const data = useStaticQuery(graphql`
    query GenesysDaisee {
      lockup: file(relativePath: { eq: "daisee_genesys.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      euld: file(relativePath: { eq: "EULD-daisee-Genesys.pdf" }) {
        publicURL
      }
    }
  `)

  return (
    <Site {...props}>
      <Main>
        <Intro bgColor='purple100'>
          <Welcome>
            <h1>Welcome to your daisee for Genesys Cloud Installation</h1>
            <Img style={{ maxWidth: 300, margin: '0 auto 32px' }} fluid={data.lockup.childImageSharp.fluid} />
            <b>Thank you for setting up your daisee system via the Genesys AppFoundry</b>
          </Welcome>
        </Intro>
        <Container>
          <Grid>
            <Sticky topOffset={-96}>
              {({ style, isSticky }) => (
                <StickyMenu style={style} isSticky={isSticky}>
                  {menu.map(item => (
                    <MenuItem active={active === item.id} onClick={() => handleClick(item.id)} key={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
                </StickyMenu>
              )}
            </Sticky>
            <Content>
              <div ref={refs.process}>
                <Heading>The daisee Process</Heading>
                <p>
                  To get you up and running quickly and deliver you value from day one, we setup a segregated customer
                  environment with a few thousand call files, evaluating each call against v1.0 of daisee’s Automated
                  Programmable Scorecard.
                  <br />
                  <br /> A daisee Customer Success Manager will contact you within 24 hours. Here are some things they
                  will discuss with you.
                </p>
              </div>

              <div ref={refs.prepare}>
                <Heading>What we need you to prepare</Heading>
                <b>High quality stereo call recordings:</b>
                <Ul>
                  <Li>
                    Stereo or dual channel with agent and customer audio recorded on separate channels on the same audio
                    file.
                  </Li>
                  <Li>
                    High quality: minimum Bit Rate 64kbps, Sample Rate 8kHz, and in an uncompressed WAV file format or
                    lossless G.711 codec
                  </Li>
                  <Li>These will be taken from your Genesys Cloud environment.</Li>
                </Ul>
                <b>Metadata:</b>
                <p>Corresponding call metadata available, example of useful fields:</p>
                <Ul>
                  <Li>Queue identifier (if applicable)</Li>
                  <Li>Reference number (unique identifier for the call as provided by your telephony system)</Li>
                  <Li>Call start date</Li>
                  <Li>Duration (length of) entire call</Li>
                  <Li>Direction (inbound or outbound)</Li>
                  <Li>Stereo recording agent side (e.g. left channel)</Li>
                  <Li>Agent full name or employee number (We recommend agent name)</Li>
                </Ul>
                <b>Scorecards:</b>
                <p>We will need you to provide daisee an example of your current scorecard(s).</p>
              </div>
              <div ref={refs.work}>
                <Heading>How we will work together</Heading>
                <p>
                  We will build the first iteration of the Programmable Scorecard to evaluate the calls in your Genesys
                  environment:
                </p>
                <Ul>
                  <Li>
                    Understand the current QA process, scorecard flow and its relevance to your teams or product queues.
                  </Li>
                  <Li>
                    Discuss additional questions from daisee’s question library regarding agent’s conduct and
                    conversational metrics:
                  </Li>
                </Ul>
                <p>When that is complete and meeting needs, we will onboard your team.</p>
              </div>
              <div ref={refs.onboarding}>
                <Heading>Onboard and Training</Heading>
                <p>
                  We arrange a training session with all users (one-off one-to-many session in-person). Cover all of the
                  functionality, as well as tips and tricks.
                </p>
                <p>We workshop how daisee support with change management considerations:</p>
                <Ul>
                  <Li>How will this affect your workflow?</Li>
                  <Li>What's the best way to augment the existing process?</Li>
                  <Li>What's the best way to communicate to different teams?</Li>
                </Ul>
              </div>
              <div ref={refs.checkin}>
                <Heading>Check-ins</Heading>
                <p>Between us, we will agree and set-up recurring check-ins for:</p>
                <Ul>
                  <Li>Feedback</Li>
                  <Li>Tips and Tricks from usage observations</Li>
                  <Li>Calibrate configuration if needed</Li>
                </Ul>
              </div>
              <div ref={refs.support}>
                <Heading>Further Support</Heading>
                <Ul>
                  <Li>
                    Support - <a href='mailto:support@daisee.com'>support@daisee.com</a>
                  </Li>
                  <Li>
                    Sales Support - <a href='mailto:sales@daisee.com'>sales@daisee.com</a>
                  </Li>
                  <Li>
                    Privacy Policy - <Link to='/privacy-policy'>daisee.com/privacy-policy/</Link>
                  </Li>
                </Ul>
              </div>
              <div ref={refs.faq}>
                <Heading>FAQ</Heading>
                <div className='item'>
                  <b>How do we log in to daisee?</b>
                  <p>
                    User log ins are usually requested by customer with the level of access the user needs to have. Once
                    the list was given, we add this internally with the user receiving an email with a temporary
                    password which is only valid for 7 days. In some cases, if SSO log in is already set up, users can
                    just simply use that for log in even without using the log in credentials from the email.
                  </p>
                </div>
                <div className='item'>
                  <b>Can we specifically apply scorecard questions in the specific calls?</b>
                  <p>
                    Yes, we can do this through scorecard conditionals and appropriate metadata fields. (I.e sales
                    questions for sales queues).{' '}
                  </p>
                </div>
                <div className='item'>
                  <b>Who or where do we go for any changes, bugs, feature request, or user access for daisee?</b>
                  <p>
                    We have a support desk that can help with any customer queries like scorecard improvements, bug
                    reports, user access. Email support@daisee.com.
                  </p>
                </div>
                <div className='item'>
                  <b>Is there a way in daisee to evaluate individual agent performance and team performance?</b>
                  <p>
                    Yes, users can have the option to view an agent dashboard, outlining specific agents’ performance
                    over time. Agents can also be grouped into teams allowing users to compare team performance with all
                    data able to be exported. Your Customer Success Manager can help you set this up.
                  </p>
                </div>
                <div className='item'>
                  <b>Can we get recurring automated reports?</b>
                  <p>
                    Yes, we can do automated reports based on the fields or requirements clients need to see in the
                    report. Client usually identifies what fields should be included in the report and we generate a
                    report through that (i.e agent name, call id, question scores, etc.)
                  </p>
                </div>
              </div>
              <div ref={refs.terms}>
                <Heading>Terms & Conditions</Heading>
                <p>
                  Read our{' '}
                  <Link to='/product/genesys/landing/terms-and-conditions'> End User License Deed here</Link>
                </p>
              </div>
            </Content>
          </Grid>
        </Container>
      </Main>
      <Footer />
    </Site>
  )
}

export const Welcome = styled.div`
  padding: 0 1.5rem;
  text-align: center;
  h1,
  h2 {
    margin: 0;
  }

  h1 {
    color: ${props => props.theme.colors.purple};

    padding-bottom: 1.5rem;
    letter-spacing: -0.5px;
    max-width: 20ch;
    text-align: center;
    margin: 0 auto;
    line-height: 1.2;
  }
`

const Heading = styled.h2`
  color: ${props => props.theme.colors.purple};
  padding-bottom: 1rem;
`

const StickyMenu = styled.div<{ isSticky: boolean }>`
  padding-top: ${({ isSticky }) => (isSticky ? '6rem' : 0)};
  display: grid;
  gap: 0.25rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }

  div {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.green};
    }
  }
`

const MenuItem = styled.div<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.colors.green : undefined)};
  font-weight: ${({ theme, active }) => (active ? 'bold' : undefined)};
`

export const Content = styled.div`
  max-width: 65ch;
  display: grid;
  gap: 3rem;

  a {
    color: ${props => props.theme.colors.purple};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    line-height: 1.7;
    margin-bottom: 0.5rem;
  }

  b {
    margin: 0.5rem 0 0.75rem;
    display: block;
  }

  .item {
    margin-bottom: 1.5rem;
  }
`

const Ul = styled.ul`
  list-style: unset;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`

const Li = styled.li`
  padding-bottom: 0.5rem;
  padding-left: 2rem;

  a {
    color: ${props => props.theme.colors.purple};

    &:hover {
      text-decoration: underline;
    }
  }

  &:before {
    content: '\u2022';
    color: ${({ theme }) => theme.colors.purple};
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2em;
  }
`

const Grid = styled(StickyContainer)`
  display: grid;
  grid-template-columns: 208px 1fr;
  gap: 3rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    display: block;
  }
`

export default GenesysLanding
